
              @import "@/assets/css/variables.scss";
            

























































































































































































































































































































// @import '@/assets/css/pages/transferFunds.scss';
.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $text-primary;
  margin-bottom: 16px;
}
.transfer_fund {
  margin: 0 auto;
  max-width: 392px;

  .btn_wrapper {
    display: flex;
    justify-content: center;
  }
}
.tips p {
  position: relative;
  line-height: 18px;
  padding: 0 10px;
  &::before {
    content: '';
    position: absolute;
    @include rtl-sass-prop(left, right, 0);
    top: 7px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $text-placeholder;
    font-size: 20px;
  }
}
.icon {
  margin: 0 8px;
  cursor: pointer;
}
